import React, { useState, useEffect } from "react";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import db from "../../Firebase/fbconfig";
import { getDatabase, ref, set,push } from "firebase/database";
//import ReactJsAlert from "reactjs-alert";

function AddMember(props) {
  useEffect(() => {
    console.log("objecttt", props.data);
  }, [props.data]);

  const onCloseMethod = async () => {
    props.onCloseCallBackk(false);
  };

  const [memid, setmemid] = useState("");
  const [memname, setmemname] = useState("");

  // const { selectedOption } = this.state;

  const AddDriver = async () => {
    const postListRef = ref(db, "/Chat/" + props.data + "/members");
    const newPostRef = push(postListRef);
    set(newPostRef, {
        mem_Id: memid,
        mem_name: memname,
    });
  };
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              Add Member
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div
            className="modal-body"
            style={{ height: "400px", overflowY: "auto" }}
          >
            <div className="px-2"></div>

            <div className="form-group row">
              <div className="col-md-6">
                <label htmlFor="task-title">Member Id</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={memid}
                  max="8"
                  onChange={(val) => setmemid(val.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="task-title">Member Name</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={memname}
                  onChange={(val) => setmemname(val.target.value)}
                />
              </div>
            </div>

            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary"
                // className="close"

                onClick={AddDriver}
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default AddMember;
