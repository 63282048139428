import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import BookingDetails from "./bookingdetails";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable,MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Order History", link: "#" },
        { title: "View Order History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      activeid: {},
    };
    this.toggle = this.toggle.bind(this);
    this.handleDefault = this.handleDefault.bind(this);
  }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };
  handleChange = async (e) => {
    console.log(this.state.data);
    console.log(this.state.filter);
    let cart = this.state.data.filter(
      (item) => item.cart_data == this.state.filter
    );
    console.log(cart, "x");
    this.state.cart = cart;
    this.setState({});
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    console.log("pqr", this.state.result1);
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    let result = await HttpClient.requestData("orderHistory", "GET");
    console.log("abc", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log("xyz", this.state.result);
      this.setState({ result1: result.user_data });
      // console.log("xyz", this.state.result1);

      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          username: element.firstname + " " + element.lastname,
          order_id: element.order_id,
          total: element.total,

          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
            >
              {element.status ? "Confirmed" : "Pending"}
            </button>
          ),
          view: (
            <button
              title="Delete"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/ecom_banner/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
        },
        // {
        //     label: "User Name",
        //     field: "username",
        
        //     width: 270,
        // },

        {
          label: "Order Id",
          field: "order_id",
          width: 270,
        },
        {
          label: "Total",
          field: "total",
          width: 270,
        },
        // {
        //     label: "Sub total",
        //     field: "Subtotal",
        
        //     width: 270,
        // },
        // {
        //     label: "Discount",
        //     field: "discount",
        
        //     width: 270,
        // },
        // {
        //     label: "Total Amount",
        //     field: "seller_comission",
        
        //     width: 270,
        // },
        // {
        //     label: "User Name",
        //     field: "username",
        
        //     width: 270,
        // },
        // {
        //   label: "Points",
        //   field: "points",
    
        //   width: 270,
        // },
        // {
        //     label: "Status",
        //     field: "status",
        
        //     width: 100,
        // },

        {
          label: "Status",
          field: "status",
          width: 100,
        },
        {
          label: "View Details",
          field: "view",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody></CardBody>
                </Card>
              </Col>
            </Row> */}

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.ismodalopen ? (
                    <BookingDetails
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.activeid}
                    />
                  ) : null}
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
