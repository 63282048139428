import React, { Component, useEffect, useState } from "react";
import {
    Col,
    Row,
    Container,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import db from "../../Firebase/fbconfig";
import { getDatabase, ref, child, get } from "firebase/database";

// create a component
const ShowUserList = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "User", link: "#" },
        { title: "User List", link: "#" },
    ])

    const [allUser, setAllUser] = useState([])

    useEffect(() => {
        getAllUsers()
    }, [])

    const getAllUsers = () => {
        const dbRef = ref(db);
        get(child(dbRef, `/users`))
            .then((snapshot) => {
                // console.log("snapshot", snapshot.val());
                if (snapshot.exists()) {
                    let arr = []
                    Object.values(snapshot.val()).forEach((element, index) => {
                        let rows = {
                            sl: index + 1,
                            userId: element.id,
                            userName: `${element.firstname} ${element.lastname}`
                        }

                        arr = [...arr, rows]

                        if(index == (Object.values(snapshot.val()).length - 1)){
                            setAllUser(arr)
                        }
                    })
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={breadcrumbItems[0].title}
                        breadcrumbItems={breadcrumbItems}
                    />

                    <Row>
                        <Col xl={12}>
                            <div className="card p-3">
                                <MDBDataTableV5
                                    hover
                                    entriesOptions={[5, 20, 25]}
                                    entries={5}
                                    pagesAmount={4}
                                    data={{
                                        columns: [
                                            {
                                                label: "Sl.",
                                                field: "sl",

                                                width: 150,
                                            },
                                            {
                                                label: "User Id",
                                                field: "userId",

                                                width: 270,
                                            },
                                            {
                                                label: "User Name",
                                                field: "userName",

                                                width: 100,
                                            },
                                        ],
                                        rows: allUser,
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ShowUserList;
